import "./ThemeSwitch.scss";
import React from "react";
import { useThemeContext } from "../../../store/ThemeContext";

const ThemeSwitch = () => {
  const { toggleTheme } = useThemeContext();

  return (
    <>
      <div className="T2B-ThemeSwitch" onClick={toggleTheme}>
        <div className="T2B-ThemeSwitch__Moon" />
        <div className="T2B-ThemeSwitch__Earth" />
      </div>
    </>
  );
};

export default ThemeSwitch;
