import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Link } from "gatsby";
import HeaderLinkIndicator from "~/components/Header/HeaderLinkIndicator";
import ThemeSwitch from "./ThemeSwitch";
import Burger from "./Burger";
import Logo from "../Logo";
import ClassList from "~/utils/helpers/ClassList";
import { useSpring, animated } from "react-spring";
import Text from "~/components/Text";

interface HeaderProps {
  location: Location;
}

// const links = [
//   {
//     text: "Home",
//     path: "/",
//   },
//   {
//     text: "Design",
//     path: "/design",
//   },
//   {
//     text: "About",
//     path: "/about",
//   },
//   {
//     text: "Contact",
//     path: "/contact",
//   },
// ];

const Header: React.FC<HeaderProps> = ({ location }) => {
  const [mobileActive, setMobileActive] = useState(false);

  const styleProps = useSpring({
    config: {
      duration: mobileActive ? 500 : 350,
    },
    transform: mobileActive ? "scale(200)" : "scale(1)",
  });

  useEffect(() => {
    /**
     * Close mobile menu on navigation
     */
    setMobileActive(false);
  }, [location.pathname]);

  return (
    <>
      <header className="T2B-Header">
        <div className="T2B-Header__Wrapper">
          <Link to="/">
            <h1 className="T2B-Header__Section T2B-Header__Title">
              <Logo />
            </h1>
          </Link>

          <div className="T2B-Header__Section T2B-Header__Links">
            {/* {links
              // filter out home for desktop header
              .filter((e) => e.path !== "/")
              .map((link) => {
                const { path, text } = link;
                const regex = new RegExp("^" + path);
                const active = location.pathname.match(regex);
                return (
                  <React.Fragment key={path}>
                    <Link to={path} className="T2B-Header__Link">
                      <Text>{text}</Text>
                    </Link>
                    <HeaderLinkIndicator active={active} />
                  </React.Fragment>
                );
              })} */}
            <ThemeSwitch />
          </div>
        </div>
      </header>
      <header
        className={ClassList.join(
          "T2B-Header--mobile",
          ClassList.bind({ "is-active": mobileActive })
        )}
      >
        <div className="T2B-Header--mobile__Top">
          <Logo />

          <Burger active={mobileActive} setActive={setMobileActive} />
        </div>
        <animated.div
          style={styleProps}
          className={ClassList.join("T2B-Header--mobile__Circle")}
        />
        <div className="T2B-Header--mobile__Wrapper">
          <ThemeSwitch />
          <div className="T2B-Header__Section T2B-Header--mobile__Links">
            {/* {links.map((link: { text: string; path: string }) => {
              const { text, path } = link;
              const regex = new RegExp("^" + path);
              let active: any = location.pathname.match(regex);
              if (path === "/") {
                // exact match for home
                active = location.pathname === path;
              }
              return (
                <Link key={path} to={path} className="T2B-Header--mobile__Link">
                  <Text
                    fontSize="3rem"
                    className="T2B-Header--mobile__Link__Text"
                  >
                    {text}
                  </Text>
                  {active && <HeaderLinkIndicator active />}
                </Link>
              );
            })} */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
