import "./Text.scss";
import React from "react";
import ClassList from "../../utils/helpers/ClassList";
import { Link } from "gatsby";

type Elements =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "span"
  | "a"
  | "small"
  | "div";

type LineHeights = "16" | "20" | "24" | "32" | "40" | "48";

type FontWeight = "medium" | "bold";

type FontSize = "12" | "16" | "24" | "32" | "40";

type Color = "content";

type Alignment = "left" | "center" | "right";
interface Props {
  className?: string;
  fontSize?: FontSize;
  lineHeight?: LineHeights;
  fontWeight?: FontWeight;
  as?: Elements;
  to?: string;
  color?: Color;
  style?: Record<string, unknown>;
  href?: string;
  align?: Alignment;
}

const Text: React.FC<Props> = (props) => {
  const {
    children,
    className,
    as = "div",
    to,
    color,
    lineHeight,
    fontSize = "14",
    fontWeight,
    align = "left",
    href,
    style,
    ...rest
  } = props;

  const isLink = to || href;

  let Component: any = as;
  if (href) {
    Component = "a";
  } else if (to) {
    Component = "Link";
  }
  const sizeClassName = `T2B-Text--size-${fontSize}`;
  const fontWeightClassName = fontWeight ? `T2B-Text--${fontWeight}` : "";
  const lineHeightClassName = lineHeight ? `T2B-Text--lh-${lineHeight}` : "";
  const colorClassName = `T2B-Text--${color || "content"}`;
  const alignClassName = `T2B-Text--align-${align}`;

  return (
    <Component
      style={{ ...style }}
      className={ClassList.join(
        "T2B-Text",
        sizeClassName,
        fontWeightClassName,
        lineHeightClassName,
        colorClassName,
        className,
        alignClassName,
        ClassList.bind({ link: isLink })
      )}
      to={to}
      href={href}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Text;
