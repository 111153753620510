import React, { useState } from "react";
import useIsClient from "../../hooks/useIsClient";
import useTimeout from "../../hooks/useTimeout";
import ClassList from "../../utils/helpers/ClassList";
import convertTimezone from "../../utils/helpers/convertTimezone";
import Text from "../Text";
import "./Footer.scss";

const Footer: React.FC = () => {
  const date = convertTimezone(new Date());
  const dateOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const [rerender, setRerender] = useState(0);

  const { isClient } = useIsClient();

  useTimeout(
    () => {
      setRerender(rerender + 1);
    },
    1000,
    []
  );

  return (
    <footer className="T2B-Footer">
      <div className="T2B-Footer__Wrapper">
        <div className="T2B-Footer__Left">
          <div className="T2B-Footer__Contact">
            <Text className="T2B-Footer__Title">Contact</Text>
            <Text
              className="T2B-Footer__Value link"
              href="mailto:hello@timetobedesign.com"
            >
              hello@timetobedesign.com
            </Text>
            <Text className="T2B-Footer__Value link" href="tel:+302109317411">
              (0030) 210 93 17 411
            </Text>
          </div>
          <div className="T2B-Footer__Connect">
            <Text className="T2B-Footer__Title">Connect</Text>
            <Text>
              <div className="T2B-Footer__SocialLinks">
                <SocialLink
                  variant="Facebook"
                  to="https://www.facebook.com/timetobedesign/"
                >
                  FB
                </SocialLink>
                <SocialLink
                  variant="Instagram"
                  to="https://www.instagram.com/time_to_be_design"
                >
                  INS
                </SocialLink>
                <SocialLink
                  variant="Behance"
                  to="https://www.behance.net/makrinaoikonomidou"
                >
                  BE
                </SocialLink>
              </div>
            </Text>
          </div>
        </div>
        <div className="T2B-Footer__Right">
          <Text as="span" className="T2B-Footer__Title T2B-Footer__Clock">
            ATH{" "}
            {isClient && (
              <Text as="span">
                {date.toLocaleTimeString("en-us", dateOptions)}
              </Text>
            )}
          </Text>

          <Text className="T2B-Footer__Value">© Time to Be 2021</Text>
        </div>
      </div>
    </footer>
  );
};

interface SocialLinkProps {
  to: string;
  variant: "Facebook" | "Instagram" | "Behance";
}

const SocialLink: React.FC<SocialLinkProps> = (props) => {
  const { to, children, variant } = props;
  return (
    <a
      target="_blank"
      rel="noreferrer nofollow"
      href={to}
      className={ClassList.join("T2B-Footer__SocialLink")}
      title={variant}
    >
      <Text className={`social--${variant}`}>{children}</Text>
    </a>
  );
};

export default Footer;
