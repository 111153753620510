function convertTimezone(date: any) {
  if (typeof window !== undefined) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        {
          timeZone: "Europe/Athens",
        }
      )
    );
  }
  return date;
}

export default convertTimezone;
