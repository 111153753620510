import { toPairs } from "ramda";

/**
 * Provides helpers for react's `className` attribute
 */
class ClassList {
  /**
   *
   * This is similar to Angular's `ngClass` and Vue's `v-bind:class`
   * It conditionally concatenates classNames and returns a string.
   *
   */
  static bind(conditionalClasses: Record<string, unknown> = {}): string {
    let className = "";

    const entries: [string, any][] = toPairs(conditionalClasses);
    entries.forEach((entry: string[]) => {
      const [name, value] = entry;
      if (value) {
        if (className) {
          // add space
          className += " ";
        }
        // concat className
        className += name;
      }
    });

    return className;
  }

  /**
   * Concatenates classNames
   * Mostly used to concatenate dynamic variables (i.e. optional `className?` prop etc)
   * Does not render unnecessary spaces, `undefined` or `null` classNames
   */
  static join(...args: (string | undefined)[]): string {
    let className = "";
    for (const name of args) {
      if (name) {
        if (className) {
          // add space
          className += " ";
        }
        className += name;
      }
    }
    return className;
  }
}

export default ClassList;
