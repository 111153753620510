import React from "react";
import ClassList from "../../../utils/helpers/ClassList";
import "./Burger.scss";

interface Props {
  active: boolean;
  setActive: (active: boolean) => void;
}

const Burger: React.FC<Props> = (props) => {
  const { active, setActive } = props;

  return (
    <button
      className={ClassList.join(
        "T2B-Burger",
        "hamburger hamburger--spring-r",
        ClassList.bind({ "is-active": active })
      )}
      type="button"
      onClick={() => setActive(!active)}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  );
};

export default Burger;
