import * as React from "react";
import "./Main.scss";
import { useLocation } from "@reach/router";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { ThemeProvider } from "~/store/ThemeContext";

const IndexLayout: React.FC = (props) => {
  const { children } = props;

  const location = useLocation();

  return (
    <ThemeProvider>
      <Header location={location} />
      <main className="T2B-Main">
        <div className="T2B-Main__Content">{children}</div>
      </main>
      <Footer />
    </ThemeProvider>
  );
};

export default IndexLayout;
