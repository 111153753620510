import React, { createContext, useContext } from "react";
import useDarkMode from "use-dark-mode";

export enum Theme {
  dark = "dark",
  light = "light",
}

interface ThemeContextType {
  theme: Theme;
  toggleTheme: any;
}

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

export const ThemeProvider: React.FC = ({ children }) => {
  const darkMode = useDarkMode(undefined, {
    storageKey: "T2B-DARK-THEME",
    classNameDark: "body--dark",
    classNameLight: "body--light",
  });

  const value = {
    theme: darkMode.value ? Theme.dark : Theme.light,
    toggleTheme: darkMode.toggle,
  };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
