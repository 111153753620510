import { useRef, useEffect } from "react";

/**
 * Custom hook to use setTimeout in functional components without memory leaks
 */
function useTimeout(callback: any, delay: number, args: Array<any>): void {
  const timeout = useRef<any>();
  useEffect(() => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(callback, delay);

    return (): void => {
      clearTimeout(timeout.current);
    };
    // eslint-disable-next-line
  }, [callback, delay, ...args]);
}

export default useTimeout;
