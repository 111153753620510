// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-about-page-about-page-tsx": () => import("./../../../src/pages/AboutPage/AboutPage.tsx" /* webpackChunkName: "component---src-pages-about-page-about-page-tsx" */),
  "component---src-pages-about-page-index-ts": () => import("./../../../src/pages/AboutPage/index.ts" /* webpackChunkName: "component---src-pages-about-page-index-ts" */),
  "component---src-pages-about-ts": () => import("./../../../src/pages/about.ts" /* webpackChunkName: "component---src-pages-about-ts" */),
  "component---src-pages-contact-page-contact-page-tsx": () => import("./../../../src/pages/ContactPage/ContactPage.tsx" /* webpackChunkName: "component---src-pages-contact-page-contact-page-tsx" */),
  "component---src-pages-contact-page-index-ts": () => import("./../../../src/pages/ContactPage/index.ts" /* webpackChunkName: "component---src-pages-contact-page-index-ts" */),
  "component---src-pages-contact-ts": () => import("./../../../src/pages/contact.ts" /* webpackChunkName: "component---src-pages-contact-ts" */),
  "component---src-pages-design-page-design-page-tsx": () => import("./../../../src/pages/DesignPage/DesignPage.tsx" /* webpackChunkName: "component---src-pages-design-page-design-page-tsx" */),
  "component---src-pages-design-page-index-ts": () => import("./../../../src/pages/DesignPage/index.ts" /* webpackChunkName: "component---src-pages-design-page-index-ts" */),
  "component---src-pages-design-ts": () => import("./../../../src/pages/design.ts" /* webpackChunkName: "component---src-pages-design-ts" */),
  "component---src-pages-home-page-home-page-tsx": () => import("./../../../src/pages/HomePage/HomePage.tsx" /* webpackChunkName: "component---src-pages-home-page-home-page-tsx" */),
  "component---src-pages-home-page-index-ts": () => import("./../../../src/pages/HomePage/index.ts" /* webpackChunkName: "component---src-pages-home-page-index-ts" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-not-found-page-index-ts": () => import("./../../../src/pages/NotFoundPage/index.ts" /* webpackChunkName: "component---src-pages-not-found-page-index-ts" */),
  "component---src-pages-not-found-page-not-found-page-tsx": () => import("./../../../src/pages/NotFoundPage/NotFoundPage.tsx" /* webpackChunkName: "component---src-pages-not-found-page-not-found-page-tsx" */)
}

