import React from "react";
import "./Logo.scss";

const Logo: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.208"
      height="16"
      viewBox="0 0 49.208 16"
      className="T2B-Logo"
    >
      <path
        d="M1677.634 577.423h-12.126v2.333h4.661v13.311h2.8v-13.311h4.661z"
        transform="translate(-1665.508 -577.067)"
      />
      <path
        d="M1708.749 592.272v-2.489l6.052-5q.779-.667 1.235-1.122a4.244 4.244 0 0 0 .768-1.056 2.805 2.805 0 0 0 .312-1.311 2.4 2.4 0 0 0-.745-1.833 2.792 2.792 0 0 0-1.991-.7 2.244 2.244 0 0 0-2.036.922 3.617 3.617 0 0 0-.7 2.255h-2.892a6.493 6.493 0 0 1 .6-2.778 4.814 4.814 0 0 1 1.9-2.089 5.455 5.455 0 0 1 3.082-.8 6.662 6.662 0 0 1 2.948.622 4.694 4.694 0 0 1 1.981 1.745 4.748 4.748 0 0 1 .7 2.567 5.425 5.425 0 0 1-.478 2.344 6.316 6.316 0 0 1-1.157 1.711 22.448 22.448 0 0 1-1.813 1.633l-3.493 2.889h6.986v2.489z"
        transform="translate(-1695.408 -576.272)"
      />
      <path
        d="M1755.68 593.068v-15.644h7.654a5.135 5.135 0 0 1 2.4.533 3.658 3.658 0 0 1 1.546 1.466 4.16 4.16 0 0 1 .523 2.067 3.754 3.754 0 0 1-.523 1.978 3.475 3.475 0 0 1-1.458 1.333 3.809 3.809 0 0 1 1.891 1.489 4.21 4.21 0 0 1 .668 2.355 4.516 4.516 0 0 1-.567 2.267 4.062 4.062 0 0 1-1.624 1.577 5 5 0 0 1-2.436.578zm7.142-9.111a2.235 2.235 0 0 0 1.635-.578 2.078 2.078 0 0 0 .59-1.555 1.959 1.959 0 0 0-.6-1.511 2.365 2.365 0 0 0-1.668-.555h-4.25v4.2zm.267 6.755a2.482 2.482 0 0 0 1.779-.611 2.2 2.2 0 0 0 .645-1.678 2.273 2.273 0 0 0-.645-1.7 2.432 2.432 0 0 0-1.779-.633h-4.561v4.622z"
        transform="translate(-1727.861 -577.068)"
      />
      <g>
        <path
          d="M1811.059 616.054a2.549 2.549 0 0 1-2.594-2.594 2.4 2.4 0 0 1 .746-1.751 2.509 2.509 0 0 1 1.848-.746 2.381 2.381 0 0 1 1.767.746 2.425 2.425 0 0 1 .729 1.751 2.54 2.54 0 0 1-.729 1.849 2.382 2.382 0 0 1-1.767.745z"
          transform="translate(44.116 .565) translate(-1808.465 -600.826)"
        />
        <path
          d="M1811.059 583.19a2.549 2.549 0 0 1-2.594-2.595 2.4 2.4 0 0 1 .746-1.751 2.509 2.509 0 0 1 1.848-.746 2.381 2.381 0 0 1 1.767.746 2.425 2.425 0 0 1 .729 1.751 2.54 2.54 0 0 1-.729 1.849 2.382 2.382 0 0 1-1.767.746z"
          transform="translate(44.116 .565) translate(-1808.465 -578.098)"
        />
      </g>
    </svg>
  );
};

export default Logo;
